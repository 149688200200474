import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


import {AppMaterial} from "../../app-material";
import {FiltersComponent} from "./filters.component";
import {InputControlModule} from "../input-control/input-control-module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    FiltersComponent
  ],
  providers: [
  ],
  exports: [
    FiltersComponent
  ]
})
export class FiltersModule {
}
