import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Pagination} from "../../../common/components/pagination";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalService} from "../../_modal/modal.service";
import {Router} from "@angular/router";

@ Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent extends Pagination implements OnInit, AfterViewInit {
  form: FormGroup;
  selection: any = {};
  @Input() currentUser: any = {};
  config = [
    {
      title: 'Credits',
      prop: {
        key: 'credits',
        placeholder: 'Credits Amount',
        value: '',
        label: '',
        type: 'number'
      }
    },
    {
      title: 'Give Credits',
      prop: {
        key: 'giveCredits',
        placeholder: 'Give Credits',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
    {
      title: 'Block Account',
      prop: {
        key: 'blockAccount',
        placeholder: 'Block Account',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
    {
      title: 'Unblock Account',
      prop: {
        key: 'unblockAccount',
        placeholder: 'Unblock Account',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
    {
      title: 'Refund',
      prop: {
        key: 'refund',
        placeholder: 'Refund',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
  ];
  @Output() back = new EventEmitter();

  constructor(injector: Injector, protected _fb: FormBuilder, protected modalService: ModalService, protected router: Router) {
    super(injector);
    this.form = this._fb.group({});
    this.tablesData = this.cm.getRentalAttributes();
    this.pointers = [
      {
        objectName: 'chargingBox',
        relationName: 'pickedFrom'
      },
      {
        objectName: 'chargingBox',
        relationName: 'returnedTo'
      },
      // {
      //   objectName: 'Address',
      //   relationName: 'address'
      // },
      {
        objectName: 'RentalInvoice',
        relationName: 'rentalInvoice'
      }
    ];
    this.objectName = 'Rental';
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.filters = {operation: 'EqualTo', fieldKey: 'user', fieldValue: this.currentUser['object']};
    this.getProducts();
  }

  _back() {
    this.back.emit();
  }
  selectionModelChange(event) {
    this.selection = event;
  }

  _submit1(formValues) {
    if (this.selection.selected.length) {
      if (formValues['blockAccount']) {
        let userObjectOperations = [];
        userObjectOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('blockUser', {userId: data['id']});
        });
        Promise.all(userObjectOperations).then(savedAll => {
          this.cm.showNotification('success', 'Requested users blocked successfully');
        });
      }
      if (formValues['unblockAccount']) {
        let userObjectOperations = [];
        userObjectOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('unblockUser', {userId: data['id']});
        });
        Promise.all(userObjectOperations).then(savedAll => {
          this.cm.showNotification('success', 'Requested users unblocked successfully');
        });
      }
      if (formValues['refund']) {
        let userObjectOperations = [];
        userObjectOperations = this.selection.selected.map(data => {
          let tmp = data['rental']['id'];
          return this.cm.cloudFunction('doRefund ', {rentalId: data['rental']['id']});
        });
        Promise.all(userObjectOperations).then(savedAll => {
          this.cm.showNotification('success', 'Refunded successfully');
        });
      }
      if (formValues['giveCredits']) {
        let giveCreditsOperations = [];
        giveCreditsOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('giveCredit', {userId: data['id'], amount: formValues['credits']});
        });
        Promise.all(giveCreditsOperations).then(savedAll => {
          this.cm.showNotification('success', 'Credits transferred successfully');
        });
      }
    }
  }
  loadingBtn = false;
  refundAmount;
  rentalId;
  _submit(object) {//transaction - Preauth - Balance
    const row = object.row;
    const type = object.type;
    this.rentalId = row.object.id;
    // if (type === 'partialRefund') {
    //   this.refundAmount = row.invoice.netPayableAmount + row.invoice.preAuthAmount ? row.invoice.preAuthAmount : 0;
    //   this.maxRefundAmount = row.invoice.netPayableAmount + row.invoice.preAuthAmount ? row.invoice.preAuthAmount : 0;
    // }
    this.openModal(type);
    // this.cm.cloudFunction('', {});
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  partialRefund(id: string) {
    this.loadingBtn = true;
    this.cm.cloudFunction('partialRefund', {
      rentalId: this.rentalId,
      doCalculationsOnly: true,
      rentedDurationMinutes: parseInt(this.refundAmount, 10)
    }).then(res => {
      if (confirm(res)) {
        this.cm.cloudFunction('partialRefund', {
          rentalId: this.rentalId,
          doCalculationsOnly: false,
          rentedDurationMinutes: parseInt(this.refundAmount, 10)
        }).then(response => {
          this.loadingBtn = false;
          this.cm.showNotification('success', response);
          this.modalService.close(id);

        }, err => {
          this.loadingBtn = false;
          this.modalService.close(id);
          this.cm.showNotification('danger', err);
        });
      } else {
        this.loadingBtn = false;
      }
    }, error => {
      this.loadingBtn = false;
      this.modalService.close(id);
      this.cm.showNotification('danger', error);
    });

  }
}
