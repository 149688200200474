import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {AppRoutingModule} from './app-routing.module';
import {CommonService} from '../common/services/common-service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatButtonModule} from '@angular/material';
import {ModalModule} from './_modal/modal.module';
import {FiltersModule} from '../common/components/filters/filters.module';
import {CommonModule} from '@angular/common';
import {DataTableModule} from '../common/components/data-table/data-table.module';
import {AppMaterial} from '../common/app-material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ChartModule} from 'angular2-chartjs';
import {UserListComponent} from './user-list/user-list.component';
import {UserProfileComponent} from './user-list/user-profile/user-profile.component';
import {CrudOpsComponent} from './crud-ops/crud-ops.component';
import {LoginComponent} from './login/login.component';
import {RentalDashboardComponent} from './rental-dashboard/rental-dashboard.component';
import {RentalRefundComponent} from './rental-dashboard/rental-refund/rental-refund.component';
import {AuthService} from './login/auth.service';
import {TokenStorage} from './login/token.storage';
import {AuthGuard} from './login/auth-guard.service';
import {InputControlModule} from '../common/components/input-control/input-control-module';


@NgModule({
  declarations: [
    AppComponent,
    UserListComponent,
    UserProfileComponent,
    CrudOpsComponent,
    LoginComponent,
    RentalDashboardComponent,
    RentalRefundComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FiltersModule,
    CommonModule,
    DataTableModule,
    FormsModule,
    ChartModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule,
    MatButtonModule,
    AppRoutingModule
  ],
  providers: [
    AuthService,
    TokenStorage,
    AuthGuard,
    CommonService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
