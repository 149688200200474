import {Component, Injector, OnInit} from '@angular/core';
import {Pagination} from "../../common/components/pagination";
import {FormBuilder, FormGroup} from "@angular/forms";
import {promise} from "selenium-webdriver";

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent extends Pagination implements OnInit {
  form: FormGroup;
  selection: any = {};
  currentUser: any = {};
  detailView = false;
  config = [
    {
      title: 'Credits',
      prop: {
        key: 'credits',
        placeholder: 'Credits Amount',
        value: '',
        label: '',
        type: 'number'
      }
    },
    {
      title: 'Give Credits',
      prop: {
        key: 'giveCredits',
        placeholder: 'Give Credits',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
    {
      title: 'Block Account',
      prop: {
        key: 'blockAccount',
        placeholder: 'Block Account',
        value: '',
        label: '',
        type: 'checkbox'
      }
    },
    {
      title: 'Unblock Account',
      prop: {
        key: 'unblockAccount',
        placeholder: 'Unblock Account',
        value: '',
        label: '',
        type: 'checkbox'
      }
    }
  ];
  textOptions = [
    {
      opeationKey: 'st',
      opeationValue: 'startsWith'
    },
    {
      opeationKey: 'gt',
      opeationValue: 'greaterThan'
    },
    {
      opeationKey: 'gte',
      opeationValue: 'greaterThanOrEqualTo'
    },
    {
      opeationKey: 'cb',
      opeationValue: 'containedBy'
    },
    {
      opeationKey: 'ci',
      opeationValue: 'containedIn'
    },
    {
      opeationKey: 'c',
      opeationValue: 'contains'
    },
    {
      opeationKey: 'ca',
      opeationValue: 'containsAll'
    },
    {
      opeationKey: 'cas',
      opeationValue: 'containsAllStartingWith'
    },
    {
      opeationKey: 'nx',
      opeationValue: 'doesNotExist'
    },
    {
      opeationKey: 'eq',
      opeationValue: 'equalTo'
    },
    {
      opeationKey: 'ex',
      opeationValue: 'exists'
    },
    {
      opeationKey: 'lt',
      opeationValue: 'lessThan'
    },
    {
      opeationKey: 'lte',
      opeationValue: 'lessThanOrEqualTo'
    },
    {
      opeationKey: 'nc',
      opeationValue: 'notContainedIn'
    },
    {
      opeationKey: 'ne',
      opeationValue: 'notEqualTo'
    },
  ];

  constructor(injector: Injector, private _fb: FormBuilder) {
    super(injector);
    this.form = this._fb.group({});
    this.tablesData = this.cm.getCustomerAttributes();
    this.objectName = 'User';
    this.pageNumber = 0;
    this.getProducts();
  }

  ngOnInit() {
  }

  _detail(event) {
    this.currentUser = event;
    this.detailView = true;
  }

  getProducts() {
    this.loading = true;
    this.cm.cloudFunction('fetchUsersCount', {
      filter: this.filters.fieldKey,
      filterValue: this.filters.fieldValue,
      filterType: this.filters.operation
    }).then(count => {
      this.count = count;
    });
    this.cm.cloudFunction('fetchAllUsers', {
      'displayLimit': this.pageSize,
      'page': this.pageNumber,
      filter: this.filters.fieldKey,
      filterValue: this.filters.fieldValue,
      filterType: this.filters.operation
    }).then(users => {
      // this.count = this.pag;
      if (this.pageNumber === 0)
        users.splice(20, users.length);
      this.tablesData.data = this.cm.getParseData(users, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      // For user table query
      if (this.objectName === 'User') {
        const AccountBalancePromise = [];
        const RentalPromise = [];
        const InstallationPromise = [];
        users.map((obj, index) => {
          RentalPromise.push(this.cm.getParseCount('Rental', 'user', obj));
          AccountBalancePromise.push(this.cm.getParseFind('AccountBalance', 'user', obj));
          InstallationPromise.push(this.cm.getParseFind('Installation', 'owner', obj));
        });
        Promise.all(RentalPromise).then(totalRentals => {
          totalRentals.map((totalRental, index) => {
            this.tablesData.data[index] = {...this.tablesData.data[index], ...{totalRentals: totalRental}};
          });
          this.tablesData = {...this.tablesData};
        });
        Promise.all(AccountBalancePromise).then(AccountBalances => {
          AccountBalances.map((AccountBalance, index) => {
            const object = {};
            if (AccountBalance.length) {
              object['availableBalance'] = this.cm.formatResult(AccountBalance[0], 'acid'); // 0 index because find return array
              this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
              this.tablesData.data[index] = {...this.tablesData.data[index], ...object['availableBalance']};
            }
          });
          this.tablesData = {...this.tablesData};
          debugger
        });
        Promise.all(InstallationPromise).then(appVersions => {
          appVersions.map((appVersion, index) => {
            const object = {};
            if (appVersion.length) {
              object['appVersion'] = this.cm.formatResult(appVersion[0], 'appid'); // 0 index because find return array
              this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
              this.tablesData.data[index] = {...this.tablesData.data[index], ...object['appVersion']};
            }
          });
          this.tablesData = {...this.tablesData};
        });
      }
    }, error => {
      if (error.code === 209) {
        this.cm.showNotification('danger', 'Session expired, login again');
        setTimeout(() => {
          this.cm.navigateTo('/login');
        }, 1500);
      } else {
        this.cm.showNotification('danger', error);
      }
    });
  }

  _pageChange(page) {
    debugger
    this.pageSize = page.pageSize - 1;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  _applyFilter(event) {
    debugger
    this.filters = {...event};
    this.getProducts();
  }

  selectionModelChange(event) {
    this.selection = event;
  }

  _submit(formValues) {
    if (this.selection.selected.length) {
      if (formValues['blockAccount']) {
        let userObjectOperations = [];
        userObjectOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('blockUser', {userId: data['id']});
        });
        Promise.all(userObjectOperations).then(savedAll => {
          this.cm.showNotification('success', 'Requested users blocked successfully');
        });
      }
      if (formValues['unblockAccount']) {
        let userObjectOperations = [];
        userObjectOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('unblockUser', {userId: data['id']});
        });
        Promise.all(userObjectOperations).then(savedAll => {
          this.cm.showNotification('success', 'Requested users unblocked successfully');
        });
      }
      if (formValues['giveCredits']) {
        let giveCreditsOperations = [];
        giveCreditsOperations = this.selection.selected.map(data => {
          return this.cm.cloudFunction('giveCredit', {userId: data['id'], amount: formValues['credits']});
        });
        Promise.all(giveCreditsOperations).then(savedAll => {
          this.cm.showNotification('success', 'Credits transferred successfully');
        });
      }
    }
  }
}
