import {Component, Input, OnInit, Output, ViewChild, EventEmitter, Injector} from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {CommonService} from "../../common/services/common-service";
import {Pagination} from "../../common/components/pagination";

@Component({
  selector: 'app-crud-ops',
  templateUrl: './crud-ops.component.html',
  styleUrls: ['./crud-ops.component.css']
})
export class CrudOpsComponent implements OnInit {
  @Input() loading = false;
  @Input() formData = [];

  @Output() edit = new EventEmitter();
  @Output() delete = new EventEmitter();
  @Output() addNew = new EventEmitter();

  productKey = 0;
  update = false;
  showForm = false;
  form: FormGroup;

  constructor(private _fb: FormBuilder, private cm: CommonService) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
  }

  _canceled() {
    this.form.reset();
  }

  save() {
    if (this.update === false) { // add
      this.cm.validateAllFormFields(this.form);
      if (this.form.valid) {
        this.addNew.emit(this.form.value);
      }
    } else { // update
      if (this.form.valid) {
        this.edit.emit({formValues: this.form.value, primaryKey: this.productKey});
      }
    }
  }

  _edit(row) {
    this.update = true;
    this.showForm = true;
    this.productKey = row['id'];
    setTimeout(() => {
      this.form.patchValue(row);
    }, 1000);
  }

  _addNew() {
    this.update = false;
    this.showForm = true;
    this.form.reset();
  }

  _delete(row) {
    this.delete.emit(row['id']);
  }
}
