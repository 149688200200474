import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from "../../input-control/field-config";
import {CommonService} from "../../../services/common-service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'app-data-table-dropdown',
  templateUrl: './data-table-dropdown.component.html',
  styleUrls: ['./data-table-dropdown.component.css']
})
export class DataTableDropdownComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  optionsShow = false;
  @Input() form;
  @Input() index;
  _value: any = {};
  @Input()
  set value(value) {
    this._value = value;
  }

  get value() {
    return this._value;
  }

  _prop: FieldConfig;
  @Output() valueChange = new EventEmitter();

  @Input()
  set prop(prop) {
    this._prop = prop;
  }

  get prop() {
    return this._prop;
  }

  private _options: any = [];
  get options() {
    return this._options;
  }

  ismobile = false;

  constructor(private cm: CommonService, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.ismobile = true;
      } else {
        this.ismobile = false;
      }
    });
  }

  ngAfterViewInit() {
    this.addOptions(this.prop);
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  get typeofOptions() {
    if (Promise && Promise.resolve) {
      return Promise.resolve(this.prop['options']) === this.prop['options'];
    }
  }

  addOptions(prop) {
    if (prop['options'] === 'Apk') {
      this._options = this.cm.apkOptions;
    }
    else{
      this._options = this.prop['options'];
    }
    this.optionsShow = true;

  }
  _selectionChange(event) {
    this.valueChange.emit({key:this.prop['key'], value: event['value'], index: this.index});
  }

  compareFn(a, b) {
    // console.log(a['id'], b['id']);
    return a && b && a['id'] === b['id'];
  }

  getErrorMessage() {
    // if (this.form.controls[this.prop.key].touched) {
    //   if (this.form.controls[this.prop.key].hasError('required')) {
    //     return this.prop.errorMessage['required'];
    //   }
    //   else return '';
    // }
    return '';
  }

  ngOnDestroy() {
  }
}
