import { NgModule } from '@angular/core';
import {InputControlComponent} from './input-control.component';
import { ReactiveFormsModule } from '@angular/forms';
import {FormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {AppMaterial} from "../../app-material";
import {TextInputComponent} from './text-input/text-input.component';
import {NumberInputComponent} from './number-input/number-input.component';
import {CheckboxInputComponent} from './checkbox-input/checkbox-input.component';
import {DateInputComponent} from './date-input/date-input.component';
import {DropdownInputComponent} from './dropdown-input/dropdown-input.component';
import {RadioInputComponent} from './radio-input/radio-input.component';
import {ToggleButtonComponent} from "./toggle-button/toggle-button.component";
import {FileInputComponent} from "./file-input/file-input.component";

const COMPONENTS = [
  InputControlComponent,
  TextInputComponent,
  NumberInputComponent,
  CheckboxInputComponent,
  DateInputComponent,
  DropdownInputComponent,
  RadioInputComponent,
  ToggleButtonComponent,
  FileInputComponent
];
@NgModule({
  declarations: [COMPONENTS],
  imports: [
    ReactiveFormsModule,
    FormsModule,
    CommonModule,
    AppMaterial
  ],
  providers: [],
  exports: [COMPONENTS]
})
export class InputControlModule { }
