import {Component} from '@angular/core';
import {Router} from "@angular/router";
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class AppComponent {
  title = 'app';
  showHeader = true;

  constructor(location: Location, router: Router) {
    router.events.subscribe((val) => {
      this.showHeader = true;
      if (location.path() === '/login') {
        this.showHeader = false;
      }
    });
  }

  ngOnInit() {
  }
}
