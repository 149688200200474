import {Component, Input, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig, maskedInput} from '../field-config';
import {regexValidator} from '../custom-validators';
import {CommonService} from "../../../services/common-service";

@Component({
  selector: 'app-file-input',
  templateUrl: './file-input.component.html',
  styleUrls: ['./file-input.component.scss']
})
export class FileInputComponent implements OnInit {
@Input() form;
  _prop: FieldConfig;
@Input()
  set prop(prop) {
    this._prop = prop;
    if (prop) {
      this.addControl(prop);
      this.addValidators(prop);
    }
  }

  get prop() {
    return this._prop;
  }

  constructor(private cm: CommonService) {
  }

  ngOnInit() {
  }

  addControl(prop) {
    this.form.addControl(prop.key, new FormControl(prop.value));
  }

  addValidators(prop) {
    if (prop.validation) {
      const keys = Object.keys(prop.validation);
      const validators = [];
      for (const key of keys) {
        if (key === 'required') {
          validators.push(Validators.required);
        } else if (key === 'min') {
          validators.push(Validators.min(prop.validation[key]));
        } else if (key === 'max') {
          validators.push(Validators.max(prop.validation[key]));
        } else if (key === 'minLength') {
          validators.push(Validators.minLength(prop.validation[key]));
        } else if (key === 'maxLength') {
          validators.push(Validators.maxLength(prop.validation[key]));
        } else if (key === 'regex') {
          validators.push(regexValidator(prop.validation[key]));

        } else if (key === '') {

        }
      }
      this.form.controls[prop.key].setValidators(validators);
      this.form.controls[prop.key].updateValueAndValidity();
    }
  }

  getErrorMessage() {
    if (this.form.controls[this.prop.key].touched) {
      if (this.form.controls[this.prop.key].hasError('required')) {
        return this.prop.errorMessage['required'];
      } else if (this.form.controls[this.prop.key].hasError('regex')) {
        return this.prop.errorMessage['regex'];
      } else if (this.form.controls[this.prop.key].hasError('max')) {
        return this.prop.errorMessage['max'];
      } else if (this.form.controls[this.prop.key].hasError('min')) {
        return this.prop.errorMessage['min'];
      } else if (this.form.controls[this.prop.key].hasError('maxlength')) {
        return this.prop.errorMessage['maxLength'];
      } else if (this.form.controls[this.prop.key].hasError('minlength')) {
        return this.prop.errorMessage['minLength'];
      } else {
        return '';
      }
    }

  }
  onFileChange($event) {
    const file = $event.target.files[0]; // <--- File Object for future use.
    const parseFile = new this.cm.ParseJs.File(file.name, file);
    this.form.get(this.prop['key']).setValue(parseFile); // <-- Set Value for Validation
  }
  get file() {
  if(this.form.value[this.prop['key']]) {
    const filename = this.form.value[this.prop['key']]['_name'];
    return filename.substr(filename.indexOf('_') + 1, filename.length);
  }
  else return null;
  }
}
