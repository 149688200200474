import {CommonService} from "../services/common-service";
import {Injector} from "@angular/core";

export class Pagination {
  tablesData: any = {};
  objectName = 'User';
  pageSize = 20;
  pageNumber = 1;
  count = 0;
  loading = true;
  pointers = [];

  protected cm: CommonService;

  constructor(injector: Injector) {
    this.cm = injector.get(CommonService);
  }

  getProducts() {
    this.loading = true;
    this.cm.getParse(this.objectName, this.pointers, this.pageNumber, this.pageSize, this.filters).then(result => {
      this.count = result.count;
      this.tablesData.data = this.cm.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      console.log(result);
      if (this.objectName === 'ChargingBox') {
        const statusOnlineOfflinePromise = [];
        result.results.map((obj, index) => {
          statusOnlineOfflinePromise.push(this.cm.getParseFind('ChargingBoxStatus', 'chargingBox', obj));
        });
        Promise.all(statusOnlineOfflinePromise).then(statuses => {
          statuses.map((status, index) => {
            const object = {};
            if (status.length) {
              object['chargingBoxStatus'] = this.cm.formatResult(status[0], 'chargingBoxStatusId'); // 0 index because find return array
              // this.tablesData.data[index] = {...this.tablesData.data[index], ...object};                                                                                                                                 //for battery status empty slots=btAllN(total)-btOnlinN(available) batteryStatus
              this.tablesData.data[index] = {
                ...this.tablesData.data[index], ...{
                  chargingBoxStatusObject: status[0],
                  status: object['chargingBoxStatus']['status'],
                  currentWifi: object['chargingBoxStatus']['currentWifi'],
                  batteryStatus: this.getBatteryStatus(object['chargingBoxStatus'])
                }
              };
              // this.tablesData.data[index] = {...this.tablesData.data[index], ...object['chargingBoxStatus']};
            }
          });
          this.tablesData = {...this.tablesData};
          debugger
        });
      }
      // For user table query no use doing in user list component
      if (this.objectName === 'User') {
        const AccountBalancePromise = [];
        const RentalPromise = [];
        const InstallationPromise = [];
        result.results.map((obj, index) => {
          debugger
          RentalPromise.push(this.cm.getParseCount('Rental', 'user', obj['object']));
          AccountBalancePromise.push(this.cm.getParseFind('AccountBalance', 'user', obj['object']));
          InstallationPromise.push(this.cm.getParseFind('Installation', 'user', obj['object']));
          // this.tablesData.data[index] = {...this.tablesData.data[index], ...{totalRentals: count}}
          // console.log(count)
        });
      }
      //For Rental
      if (this.objectName === 'Rental') {
        result.results.map((renatlObj, index) => {
          // Parse data for nested relation
          this.tablesData.data[index]['loadingBtn'] = false; // not using yet can use it in future to show loading sign in table row button
          this.tablesData.data[index]['rentalStatus'] = renatlObj.attributes['status'];
          if (renatlObj.attributes['pickedFrom'] && renatlObj.attributes['pickedFrom']['attributes']['address']) {
            const object = {};
            object['pickedFromAddress'] = this.cm.formatResult(renatlObj.attributes['pickedFrom']['attributes']['address'], 'pickedFromid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['returnedTo'] && renatlObj.attributes['returnedTo']['attributes']['address']) {
            const object = {};
            object['returnToAddress'] = this.cm.formatResult(renatlObj.attributes['returnedTo']['attributes']['address'], 'returnToid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['invoice']) {
            const object = {};
            object['amountCharged'] = renatlObj.attributes['invoice']['attributes']['netPayableAmount'] + (renatlObj.attributes['invoice']['attributes']['preAuthAmount'] ? renatlObj.attributes['invoice']['attributes']['preAuthAmount'] : 0);
            object['rental'] = this.cm.formatResult(renatlObj.attributes['invoice'], 'rentalid'); // 0 index because find return array
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['battery'] && renatlObj.attributes['battery']['attributes']['chargingBox']) {
            this.tablesData.data[index]['insideStation'] = renatlObj.attributes['battery']['attributes']['chargingBox']['attributes']['deviceName'];
          } else {
            this.tablesData.data[index]['insideStation'] = 'None';
          }

        });
      }
      if (this.objectName === 'DeviceLogs') {
        result.results.map((obj, index) => {
          const _object = obj.toJSON();
          const object: any = {};
          object.createdAt = _object.createdAt;
          object.logFile = _object.logFile.url;
          object.deviceName = _object.chargingBox&&_object.chargingBox.deviceName;
          this.tablesData.data[index] = {...this.tablesData.data[index], ...object};

        });
        this.tablesData = {...this.tablesData};
      }

    }, error => {
      if (error.code === 209) {
        this.cm.showNotification('danger', 'Session expired, login again');
        setTimeout(() => {
          this.cm.navigateTo('/login');
        }, 1500);
      } else {
        this.cm.showNotification('danger', error);
      }
    });
  }

  _pageChange(page) {
    this.pageSize = page.pageSize;
    this.pageNumber = page.pageNumber;
    this.getProducts();
  }

  filters: any = {};

  _applyFilter(event) {
    this.filters = {...event};
    this.getProducts();
  }

  getBatteryStatus(data) {
    console.log(data['btAllN']);
    console.log(data['btOnlinN']);
    const emptySlots = parseInt(data['btAllN'], 10) - parseInt(data['btOnlinN'], 10);
    return data['btOnlinN'] + ' / ' + emptySlots;
  }
}
