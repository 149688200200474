import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Pagination} from "../../../common/components/pagination";
import {FormBuilder, FormGroup} from "@angular/forms";

@Component({
  selector: 'app-rental-refund',
  templateUrl: './rental-refund.component.html',
  styleUrls: ['./rental-refund.component.css']
})
export class RentalRefundComponent extends Pagination implements OnInit {
  form: FormGroup;
  loadingBtn = false;
  phonenumber = '';
  filters = [];
  selection: any = {};

  constructor(injector: Injector, private _fb: FormBuilder) {
    super(injector);
    this.form = this._fb.group({});
    this.tablesData = this.cm.getRentalAttributes();
    this.pointers = [
      {
        objectName: 'chargingBox',
        relationName: 'pickedFrom'
      },
      {
        objectName: 'chargingBox',
        relationName: 'returnedTo'
      },
      {
        objectName: 'User',
        relationName: 'user'
      },
      {
        objectName: 'RentalInvoice',
        relationName: 'invoice'
      },
      {
        objectName: 'Address',
        relationName: 'pickedFrom.address'
      },
      {
        objectName: 'Address',
        relationName: 'returnedTo.address'
      }
    ];
    this.objectName = 'Rental';
    this.pageSize = 500;

    this.getProducts();
  }

  ngOnInit() {
  }

  _applyFilter(event) {
    if (this.phonenumber !== '') {
      this.filters = [event];
      this.getProducts();
    }
  }
  resetFilter() {
    this.phonenumber = '';
    this.filters = [];
    this.getProducts();
  }
  getProducts() {
    this.loading = true;
    this.cm.cloudFunction('fetchAllResults', {
      objectName: this.objectName, pointers: this.pointers, pageNumber: this.pageNumber,
      pageSize: this.pageSize, filters: this.filters, withCount: true
    }).then(result => {
      this.count = result.count;
      this.tablesData.data = this.cm.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      console.log(result);
      //For Rental
      if (this.objectName === 'Rental') {
        result.results.map((renatlObj, index) => {
          // Parse data for nested relation
          this.tablesData.data[index]['loadingBtn'] = false; // not using yet can use it in future to show loading sign in table row button
          this.tablesData.data[index]['rentalStatus'] = renatlObj.attributes['status'];
          if (renatlObj.attributes['pickedFrom'] && renatlObj.attributes['pickedFrom']['attributes']['address']) {
            const object = {};
            object['pickedFromAddress'] = this.cm.formatResult(renatlObj.attributes['pickedFrom']['attributes']['address'], 'pickedFromid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['returnTo'] && renatlObj.attributes['returnTo']['attributes']['address']) {
            const object = {};
            object['returnToAddress'] = this.cm.formatResult(renatlObj.attributes['returnTo']['attributes']['address'], 'returnToid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['invoice']) {
            const object = {};
            object['rental'] = this.cm.formatResult(renatlObj.attributes['invoice'], 'rentalid'); // 0 index because find return array
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
        });
      }

    }, error => {
      if (error.code === 209) {
        this.cm.showNotification('danger', 'Session expired, login again');
        setTimeout(() => {
          this.cm.navigateTo('/login');
        }, 1500);
      } else {
        this.cm.showNotification('danger', error);
      }
    });
  }
  _selectionModelChange(event) {
    this.selection = event;
  }

  multipleRefund() {
    if (this.selection.selected.length) {
      this.loadingBtn = true;
      // this.selection.clear();
      let userObjectOperations = [];
      this.selection.selected.map(data => {
        if (data['status'] === 'paid') {
          userObjectOperations.push(this.cm.cloudFunction('doRefund ', {rentalId: data['object']['id']}));
        }
        // console.log(data['object']['id']);
      });
      if (userObjectOperations.length) {
        Promise.all(userObjectOperations).then(savedAll => {
          this.loadingBtn = false;
          this.cm.showNotification('success', 'Refunded successfully');
        });
      } else {
        this.loadingBtn = false;
        this.cm.showNotification('danger', 'Select atleast one rental with status paid');
      }
    }
  }
}
