import {Injectable, OnDestroy} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';
import {environment} from "../../environments/environment";
import {Router} from "@angular/router";

declare const Parse: any;
declare const $: any;

@Injectable()
export class CommonService implements OnDestroy {
  private customerAttributes = {
    // btnText: '', // if add button show
    filters: {
      columns: [
        {
          column: 'username',
          type: 'text'
        }
      ],
      pointers: [],
      ObjectNames: []
    },
    columnLabels: [
      'select',
      {key: 'detail', type: 'detail', label: 'View Profile'},
      {key: 'username', type: 'simple', label: 'UID'},
      {key: 'ref_id', type: 'simple', label: 'Ref Id'},
      {key: 'username1', type: 'simple', label: 'Phone Number'},
      {key: 'createdAt', type: 'date', label: 'Created At'},
      {key: 'totalRentals', type: 'simple', label: 'Total Rentals'},
      {key: 'availableBalance', type: 'simple', label: 'Credits Available'},
      {key: 'status', type: 'simple', label: 'Status'},
      {key: 'appVersion', type: 'simple', label: 'App Version'}
    ],
    columnsToDisplay: [
      'select',
      'detail',
      'username',
      'ref_id',
      'username1',
      'createdAt',
      'totalRentals',
      'availableBalance',
      'status',
      'appVersion'
    ],
    data: []
  };
  public $pointerData = new Subject<any>();

  getPointerData(): Observable<any> {
    return this.$pointerData.asObservable();
  }

  private logsAttributes = {
    columnLabels: [
      'select',
      {key: 'logs', type: 'logs', label: ' '},
      {key: 'createdAt', type: 'date', label: 'Created At'},
      {key: 'logFile', type: 'simple', label: 'File'},
      {key: 'deviceName', type: 'simple', label: 'Device Name'},
    ],
    columnsToDisplay: [
      'select',
      'logs',
      'createdAt',
      'logFile',
      'deviceName'
    ],
    data: []
  };
  private rentalAttributes = {
    filters: {
      columns: [
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'rentedDurationMinutes',
          type: 'number'
        },
        {
          column: 'summaryStatus',
          type: 'text'
        },
        // {
        //   column: 'pickedFrom',
        //   type: 'pointer'
        // },
        // {
        //   column: 'returnTo',
        //   type: 'pointer'
        // },
        {
          column: 'user',
          type: 'pointer'
        },
        // {
        //   column: 'battery',
        //   type: 'pointer'
        // },
        {
          column: 'invoice',
          type: 'pointer'
        }
      ],
      pointers: ['user', 'invoice'],// 'pickedFrom', 'returnTo', 'user', 'battery', 'invoice'
      ObjectNames: ['User', 'RentalInvoice'],// 'ChargingBox', 'ChargingBox', 'User', 'Battery', 'RentalInvoice'
      pickedFrom: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      returnTo: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      user: [
        {
          column: 'username',
          type: 'text'
        },
        {
          column: 'email',
          type: 'text'
        },
        {
          column: 'emailVerified',
          type: 'toggle'
        }
      ],
      battery: [
        {
          column: 'btStat',
          type: 'number'
        },
        {
          column: 'btBay',
          type: 'number'
        },
        {
          column: 'bteq',
          type: 'number'
        },
        {
          column: 'btSn',
          type: 'text'
        }
      ],
      invoice: [
        {
          column: 'discount',
          type: 'number'
        },
        {
          column: 'VAT',
          type: 'number'
        },
        {
          column: 'tax',
          type: 'number'
        },
        {
          column: 'rentAmount',
          type: 'number'
        },
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'transactionAmount',
          type: 'number'
        },
        {
          column: 'totalAmount',
          type: 'number'
        },
        {
          column: 'netPayableAmount',
          type: 'number'
        },

      ],
    },
    columnLabels: ['select',
      {key: 'rentedAt', type: 'time', label: 'Check Out Time'},
      {key: 'username', type: 'simple', label: 'Phone Number'},
      {key: 'ref_id', type: 'simple', label: 'Ref Id'},
      {key: 'submit', type: 'button', label: 'Force Return'},
      {key: 'submit', type: 'partialRefund', label: 'Refund'},
      {key: 'returnedAt', type: 'time', label: 'Return Time'},
      {key: 'addressLine1', pointer: 'pickedFromAddress', type: 'pointer', label: 'Check Out Address'},
      {key: 'locationName', pointer: 'pickedFromAddress', type: 'pointer', label: 'Check Out Location Name'},
      {key: 'addressLine1', pointer: 'returnToAddress', type: 'pointer', label: 'Return Address'},
      {key: 'rentedDurationMinutes', type: 'simple', label: 'Duration'},
      {key: 'amountCharged', type: 'simple', label: 'Amount Charged'},
      {key: 'rentalStatus', type: 'simple', label: 'Rental Status'},
      {key: 'btSn', type: 'simple', label: 'Serial Number'},
      {key: 'insideStation', type: 'simple', label: 'Inside Station'},
      {key: 'status', type: 'simple', label: 'Payment Status'},

    ],
    columnsToDisplay: ['select',
      'rentedAt',
      'username',
      'ref_id',
      'forcereturn_btn',
      'refund_btn',
      'returnedAt',
      'addressLine1',
      'addressLine2',
      'locationName',
      'rentedDurationMinutes',
      'amountCharged',
      'rentalStatus',
      'btSn',
      'insideStation',
      'status',
    ],
    data: []
  };
  private promoCodesAttributes = {
    columnLabels: ['select',
      {key: 'operations', type: 'operations', label: 'operations'},
      {key: 'displayMessage', type: 'simple', label: 'Display Message'},
      {key: 'description', type: 'simple', label: 'Description'},
      {key: 'code', type: 'simple', label: 'Code'},
      {key: 'name', pointer: 'reward', type: 'pointer', label: 'Reward'},

    ],
    columnsToDisplay: ['select',
      'operations',
      'displayMessage',
      'description',
      'code',
      'name'
    ],
    data: []
  };
  private venueAttributes = {
    btnText: 'Add Venue',
    filters: {
      columns: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'City',
      'State',
      'Zip',
      'Country',
      'Venue Name', // Location Name,
      'Venue Contact Name',
      'Venue Phone',
      'Venue Email',
      'Address',
      'Hours',
      'Category',
      'Charging Station Type',
      'Powerbanks Available',
      'Open Slots Available',
      '# Rental Powerbanks',
      'Last Checkout',
      'Last Return',
      'Is Live?',
      'State Date',
      'Lifetime Rentals',
      'Rentals 7d',
      'Rentals 30d',
      'Rentals YTD',
      'Rentals 24 Hours',
      'Avg Rentals 7d',
      'Avg Rentals 30d',
      'Revenue Lifetime',
      'Revenue 7d',
      'Revene 30d',
      'Opening Timings',
      'Location'
    ],
    columnsToDisplay: ['select',
      'operations',
      'city',
      'state',
      'zip',
      'country',
      'locationName',
      'VenueContactName',
      'VenuePhone',
      'VenueEmail',
      'addressLine1',
      'Hours',
      'Category',
      'ChargingStationType',
      'PowerbanksAvailable',
      'OpenSlotsAvailable',
      '#RentalPowerbanks',
      'LastCheckout',
      'LastReturn',
      'IsLive',
      'StateDate',
      'LifetimeRentals',
      'Rentals7d',
      'Rentals30d',
      'RentalsYTD',
      'Rentals24Hours',
      'AvgRentals7d',
      'AvgRentals30d',
      'RevenueLifetime',
      'Revenue7d',
      'Revene30d',
      'openingTimings',
      'location'
    ],
    data: [
      {
        'venue_name': 'Joes Coffee',
        'contact_name': 'Joe Smith',
        'contact_phone': 2019283736,
        'contact_email': 'joesmith@coffee.com',
        'address': '392 yale avenue',
        'venue_city': 'Los Angeles',
        'venue_category': 'food and beverage',
        'venue_subcategory': 'coffee shop',
        'total_stations': 1,
        'station_type': 'small',
        'is_live': true,
        'start_date': '08-12-2019',
        'rentals_lifetime': 20,
        'rentals_7d': 2,
        'rentals_30d': 12,
        'rentals_ytd': 20,
        'rentals_avg_1d': 2.5,
        'rentals_avg_7d': 4.3,
        'rentals_avg_30d': 12,
        'revenue_lifetime': 100,
        'revenue_7d': 10,
        'revenue_30d': 90,
        'rentals_first_7d': 2,
        'rentals_first_30d': 23,
      },
      {
        'venue_name': 'Toy Shop',
        'contact_name': 'Ronald Smith',
        'contact_phone': 2019283345,
        'contact_email': 'ronaldmith@coffee.com',
        'address': '392 yale avenue',
        'venue_city': 'Los Angeles',
        'venue_category': 'food and beverage',
        'venue_subcategory': 'toy shop',
        'total_stations': 1,
        'station_type': 'small',
        'is_live': true,
        'start_date': '08-12-2019',
        'rentals_lifetime': 20,
        'rentals_7d': 4,
        'rentals_30d': 13,
        'rentals_ytd': 20,
        'rentals_avg_1d': 2.5,
        'rentals_avg_7d': 4.3,
        'rentals_avg_30d': 12,
        'revenue_lifetime': 100,
        'revenue_7d': 10,
        'revenue_30d': 90,
        'rentals_first_7d': 2,
        'rentals_first_30d': 23,
      },
    ]
  };
  private product = {
    columnLabels: ['select',
      'operations',
      'Product Key',
      'Product Name',
      'Product Secret'
    ],
    columnsToDisplay: ['select',
      'operations',
      'productKey',
      'productName',
      'productSecret'
    ],
    data: []
  };
  private chargingBox = {
    btnText: 'Add Charging Box',
    btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      pointers: ['address', 'type', 'apk'],
      ObjectNames: ['Address', 'ChargingBoxType', 'Apk'],
      type: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      address: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      apk: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      // chargingBoxStatus:[
      //   {
      //     column: 'status',
      //     type: 'text'
      //   }
      // ]
    },
    columnLabels: [
      'select',
      // {key: 'bcode', type: 'simple', label: 'Barcode'},
      {key: 'boxCode', type: 'simple', label: 'Device ID'},
      // {key: 'deviceName', type: 'simple', label: 'Name'},
      {key: 'locationName', type: 'simple', label: 'Device Location'},
      {key: 'name', type: 'simple', label: 'Apk Type'},
      {
        key: 'apkVersion',
        type: 'select',
        label: 'APK',
        prop: {
          key: 'apk',
          placeholder: 'Apk',
          value: '',
          label: '',
          type: 'select',
          isOptionsPromise: true,
          options: 'Apk',
          viewValue: 'apkVersion',
          viewKey: 'id'
        }
      },
      {key: 'currentWifi', type: 'simple', label: 'WIFI'},
      {key: 'updatedAt', type: 'date', label: 'Last Updated'},
      {key: 'batteryStatus', type: 'simple', label: 'Battery Status(Returned/Not Returned)'},
      {
        key: 'status', type: 'simple', label: 'Online Offline Status',
        prop: {
          key: 'status',
          placeholder: 'Online Offline Status',
          value: '',
          label: '',
          type: 'select',
          valueType: 'simple',
          options: [
            'online',
            'offline'
          ],
          viewValue: 'value',
          viewKey: 'id'
        }
      }
    ],
    columnsToDisplay: [
      'select',
      'boxCode',
      'locationName',
      'name',
      'apkVersion',
      'currentWifi',
      'updatedAt',
      'batteryStatus',
      'status'
    ],
    data: []
  };
  private chargingBoxEarnings = {
    filters: {
      columns: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ],
      pointers: ['address', 'type', 'apk'],
      ObjectNames: ['Address', 'ChargingBoxType', 'Apk'],
      type: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      address: [
        {
          column: 'locationName',
          type: 'text',
        },
        {
          column: 'zip',
          type: 'text',
        },
        {
          column: 'city',
          type: 'text',
        },
        {
          column: 'state',
          type: 'text',
        },
        {
          column: 'country',
          type: 'text',
        }
      ],
      apk: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      // chargingBoxStatus:[
      //   {
      //     column: 'status',
      //     type: 'text'
      //   }
      // ]
    },
    columnLabels: ['select',
      'operations',
      'Barcode',
      'Box Code (IMEI NUMBER)',
      // 'Device Secret',
      'Name',
      'Location',
      'Apk Version',
      'Total Slots',
      'Earnings'
    ],
    columnsToDisplay: ['select',
      'operations',
      'bcode',
      'boxCode',
      // 'deviceSecret',
      'deviceName',
      'locationName',
      'apkVersion',
      'totalSlots',
      'earnings'
    ],
    data: []
  };
  private ChargingBoxType = {
    btnText: 'Add Charging Box Type',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'name',
          type: 'text',
        },
        {
          column: 'description',
          type: 'text',
        },
        {
          column: 'totalSlots',
          type: 'number',
        },
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Name',
      'Description',
      'Total Slots',
    ],
    columnsToDisplay: ['select',
      'operations',
      'name',
      'description',
      'totalSlots'
    ],
    data: []
  };
  private apk = {
    btnText: 'Add Apk',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'apkUrl',
          type: 'text',
        },
        {
          column: 'apkMd5',
          type: 'text',
        },
        {
          column: 'apkVersion',
          type: 'text',
        },
        {
          column: 'active',
          type: 'toggle',
        },
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Apk Url',
      'Apk Md5',
      'Apk Version',
      // {label: 'Product Name', detailKey: 'product', column: 'productName'},
      {label: 'Type', detailKey: 'type', column: 'name'},
      'Active'
    ],
    columnsToDisplay: ['select',
      'operations',
      'apkUrl',
      'apkMd5',
      'apkVersion',
      'type',
      'active'
    ],
    data: []
  };
  private networkConfig = {
    btnText: 'Add Network',
    // btnScanText: 'Scan Charging Box',
    filters: {
      columns: [
        {
          column: 'wname',
          type: 'text',
        },
        {
          column: 'wpwd',
          type: 'text',
        },
        {
          column: 'connType',
          type: 'text',
        }
      ],
      pointers: [],
      ObjectNames: [],
    },
    columnLabels: ['select',
      'operations',
      'Wifi Name',
      'Wifi Passoword',
      'Connection Type',
    ],
    columnsToDisplay: ['select',
      'operations',
      'wname',
      'wpwd',
      'connType'
    ],
    data: []
  };
  private gc = {
    btnText: 'Add Global Config',
    // btnScanText: 'Scan Charging Box',
    columnLabels: ['select',
      'operations',
      'Name',
      'Time Data',
      'Mill',
      'Apk Url',
      'Apk Md5',
      'Apk Version',
      'Active',
      'wname',
      'wpwd',
      'Connection Type'
    ],
    columnsToDisplay: ['select',
      'operations',
      'name',
      'timeData',
      'mill',
      'apkUrl',
      'apkMd5',
      'apkVersion',
      'active',
      'wname',
      'wpwd',
      'connType'
    ],
    data: []
  };
  private statusOfChargers = {
    filters: {
      columns: [
        {
          column: 'status',
          type: 'text'
        },
        {
          column: 'temper',
          type: 'text'
        },
        {
          column: 'signal',
          type: 'text'
        },
        {
          column: 'connect',
          type: 'text'
        },
        {
          column: 'time',
          type: 'text'
        },
        {
          column: 'currentWifi',
          type: 'text'
        },
        {
          column: 'btOnlinN',
          type: 'text'
        },
        {
          column: 'phone',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'apkV',
          type: 'text'
        },
        {
          column: 'type',
          type: 'text'
        },
        {
          column: 'btAllN',
          type: 'text'
        },
        {
          column: 'chargingBox',
          type: 'pointer'
        },
      ],
      pointers: ['chargingBox'],
      ObjectNames: ['ChargingBox'],
      chargingBox: [
        {
          column: 'deviceName',
          type: 'text'
        },
        {
          column: 'bcode',
          type: 'text'
        },
        {
          column: 'boxCode',
          type: 'text'
        },
        {
          column: 'address',
          type: 'pointer'
        },
        {
          column: 'type',
          type: 'pointer'
        },
        {
          column: 'apk',
          type: 'pointer'
        }
      ]
    },
    columnLabels: ['select',
      // 'operations',
      'Barcode',
      'Temper',
      'signal',
      'connect',
      'currentWifi',
      'btOnlinN',
      'phone',
      'btAllN',
      // 'deviceName' element[this.columnLabels[ind]['detailKey']][this.columnLabels[ind]['column']]
      {label: 'deviceName', detailKey: 'chargingBox', column: 'deviceName'},

    ],
    columnsToDisplay: ['select',
      // 'operations',
      'bcode',
      'temper',
      'signal',
      'connect',
      'currentWifi',
      'btOnlinN',
      'phone',
      'btAllN',
      'deviceName'
    ],
    data: [
      {
        'ChargerId': '1234',
        'StationName': '142, East London',
        'PowerbanksAvailable': 5,
        'SlotsAvailable': 4,
        'PowerbankswithChargeover50Percent': 3,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '30%'
      },
      {
        'ChargerId': '1236',
        'StationName': '142, East London',
        'PowerbanksAvailable': 6,
        'SlotsAvailable': 3,
        'PowerbankswithChargeover50Percent': 2,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '40%'
      },
      {
        'ChargerId': '1237',
        'StationName': '142, East London',
        'PowerbanksAvailable': 5,
        'SlotsAvailable': 7,
        'PowerbankswithChargeover50Percent': 5,
        'LastCheckout': '13:45PM/03-03-2019',
        'LastReturn': '3:45PM/03-04-2019',
        'StationStatus': 'ONLINE',
        'ChargePercentage': '70%'
      }
    ]
  };
  client;

  constructor(private router: Router) {
    Parse.initialize(environment.PARSE_APP_ID);
    Parse.serverURL = environment.serverURL;
    this.optionsFetch('Apk');
    // setTimeout(()=>{},1000);
    if (this.client) this.closeLiveQueryClient();
    setTimeout(() => {
      const LiveQueryClient = Parse.LiveQueryClient;
      this.client = new LiveQueryClient({
        applicationId: environment.PARSE_APP_ID,
        serverURL: environment.PARSE_WSS_URL
      });
      this.client.open();
    }, 2000);
  }

  ngOnDestroy() {
    this.closeLiveQueryClient();
  }

  get ParseJs() {
    return Parse;
  }

  get getLiveQueryClient() {
    return this.client;
  }

  closeLiveQueryClient() {
    // return this.client.close();
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  navigateTo(url) {
    this.router.navigate([url]);
  }

  public getRentalAttributes() {
    return this.rentalAttributes;
  }

  public getlogsAttributes() {
    return this.logsAttributes;
  }

  public getPromoCodesAttributes() {
    return this.promoCodesAttributes;
  }

  public getCustomerAttributes() {
    return this.customerAttributes;
  }

  public getChargingBoxAttributes() {
    return this.chargingBox;
  }

  public getChargingBoxEarningsAttributes() {
    return this.chargingBoxEarnings;
  }

  public getApkAttributes() {
    return this.apk;
  }

  public getNetworkConfigAttributes() {
    return this.networkConfig;
  }

  public getChargingBoxTypeAttributes() {
    return this.ChargingBoxType;
  }

  public getGcAttributes() {
    return this.gc;
  }

  public getVenueAttributes() {
    return this.venueAttributes;
  }

  public getStatusOfChargersAttributes() {
    return this.statusOfChargers;
  }

  public getProduct() {
    return this.product;
  }

  getParse(objectName, pointers, pageNumber?, pageSize?, filters?) {
    // Parse.User.allowCustomUserClass(true);
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    if (pageNumber && pageSize) {
      const limit = pageSize;
      const skip = pageSize * (pageNumber - 1);
      if (pageNumber > 1)
        query.skip(skip);
      query.limit(limit);
    }
    if (filters) {
      if (filters.childKey) {
        const child = Parse.Object.extend(filters.childObjectName);
        // var Comment = Parse.Object.extend("Comment");
        let innerQuery = new Parse.Query(child);
        if (filters.operation === 'notEqualTo')
          innerQuery.notEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'EqualTo')
          innerQuery.equalTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'exists') {
          innerQuery.exists(filters.fieldKey);
          innerQuery.notEqualTo(filters.fieldKey, '');
        }
        if (filters.operation === 'doesNotExist') {
          const tmp1 = new Parse.Query(filters.childObjectName);
          tmp1.doesNotExist(filters.fieldKey);
          const tmp2 = new Parse.Query(filters.childObjectName);
          tmp2.equalTo(filters.fieldKey, '');
          innerQuery = Parse.Query.or(tmp1, tmp2);
        }
        // if (filters.operation === 'doesNotExist')
        //   query.doesNotExist(filters.fieldKey);
        if (filters.operation === 'fullText')
          innerQuery.fullText(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'startsWith')
          innerQuery.startsWith(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThan')
          innerQuery.lessThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThan')
          innerQuery.greaterThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThanOrEqualTo')
          innerQuery.lessThanOrEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThanOrEqualTo')
          innerQuery.greaterThanOrEqualTo(filters.fieldKey, filters.fieldValue);

        query.matchesQuery(filters.childKey, innerQuery);
      } else {
        if (filters.operation === 'notEqualTo')
          query.notEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'EqualTo')
          query.equalTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'exists') {
          query.exists(filters.fieldKey);
          query.notEqualTo(filters.fieldKey, '');
        }
        if (filters.operation === 'doesNotExist') {
          query.doesNotExist(filters.fieldKey);
          // query.equalTo(filters.fieldKey, '');
        }
        // if (filters.operation === 'doesNotExist')
        //   query.doesNotExist(filters.fieldKey);
        if (filters.operation === 'fullText')
          query.fullText(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'startsWith')
          query.startsWith(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThan')
          query.lessThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThan')
          query.greaterThan(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'lessThanOrEqualTo')
          query.lessThanOrEqualTo(filters.fieldKey, filters.fieldValue);
        if (filters.operation === 'greaterThanOrEqualTo')
          query.greaterThanOrEqualTo(filters.fieldKey, filters.fieldValue);

      }
    }
    query.withCount();
    query.descending('createdAt');
    if (objectName === 'Rental') {
      query.include(['pickedFrom.address']);
      query.include(['returnedTo.address']);
    }
    pointers.map(pointer => {
      query.include(pointer['relationName']);
    });
    return query.find();
  }

  getParseDataById(objectName, id) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    return query.get(id);
  }

  async getParseCount(objectName, fieldName, fieldValue) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.equalTo(fieldName, fieldValue);
    const count = await query.count();
    return count;
  }

  getParseFind(objectName, fieldName, fieldValue) {
    const Product = Parse.Object.extend(objectName);
    const query = new Parse.Query(Product);
    query.equalTo(fieldName, fieldValue);
    return query.find();
  }

  getParseData(results, pointers) {
    const arr = [];
    const arrrelatedObject = [];
    results.map((result, index) => {
      let temp: any = {};
      temp = {...temp, ...this.formatResult(result, 'id')};
      temp['object'] = result;
      arr.push(temp);
      pointers.map(pointer => {
        if (pointer['relationName'] === 'address' && result.attributes['pickedFrom'] && result.attributes['pickedFrom']['attributes'][pointer['relationName']]) {
          const object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes['pickedFrom']['attributes'][pointer['relationName']], pointer['relationName'] + 'id');
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
        } else if (result.attributes[pointer['relationName']]) {
          let object = {};
          object[pointer['relationName']] = this.formatResult(result.attributes[pointer['relationName']], 'id');
          arr[index] = {...arr[index], ...object};
          arr[index] = {...arr[index], ...object[pointer['relationName']]};
        } else {
          // console.log(index);
          // console.log(result);
          // console.log(result.attributes[pointer['relationName']]);
          // console.log('.......elseeeeeeeee.........')
        }
      });
    });
    return arr;
  }

  formatResult(result, id?) {
    let temp: any = {};
    temp = {...result.attributes};
    // temp['object'] = result;
    temp[id] = result.id;
    return temp;
  }

  formatDateTime(datetime, format) {
    const d = new Date(datetime);
    if (format === 'date')
      return d.getDate() + '-' + (d.getMonth() + 1) + '-' + d.getFullYear();
    return d.getHours() + ':' + d.getMinutes() + ':' + d.getSeconds();
  }

  getOptionParse(objectName) {
    const Product = this.ParseJs.Object.extend(objectName);
    const query = new this.ParseJs.Query(Product);
    query.descending('createdAt');
    if (objectName === 'Apk') // for showing type name in drop down in station manager actions
      query.include('type');
    return query.find();
  }

  getOptionsData(results, key) {
    const arr = [];
    let temp: any = {};
    results.map((result, index) => {
      temp = {...temp, ...this.formatResult(result, key)};
      temp['object'] = result;
      arr.push(temp);
      if (result.attributes['type']) { // for showing type name in drop down in station manager actions
        arr[index]['apkVersion'] = arr[index]['apkVersion'] + ' => ' + result.attributes['type']['attributes']['name'];
      }
    });
    return arr;
  }

  cloudFunction(funcName, params?) {
    if (params)
      return Parse.Cloud.run(funcName, params);
    return Parse.Cloud.run(funcName);
  }

  showNotification(type, message) {
    const from = 'top';
    const align = 'right';
    $.notify({
      icon: "notifications",
      message: message

    }, {
      type: type,
      timer: 3000,
      placement: {
        from: from,
        align: align
      },
      template: '<div data-notify="container" class="col-xl-4 col-lg-4 col-11 col-sm-4 col-md-4 alert alert-{0} alert-with-icon container-notif" role="alert">' +
        '<button mat-button  type="button" aria-hidden="true" class="close mat-button" data-notify="dismiss">  <i class="material-icons">close</i></button>' +
        '<i class="material-icons" data-notify="icon">notifications</i> ' +
        '<span data-notify="title">{1}</span> ' +
        '<span data-notify="message">{2}</span>' +
        '<div class="progress" data-notify="progressbar">' +
        '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
        '</div>' +
        '<a href="{3}" target="{4}" data-notify="url"></a>' +
        '</div>'
    });
  }

  setItem(item, key) {
    window.localStorage.setItem(key, item);
  }

  getItem(key) {
    return localStorage.getItem(key);
  }

// Options for data table drop downs
  private _apkOptions: any = [];
  public get apkOptions() {
    return this._apkOptions;
  }

  optionsFetch(key) {
    this.getOptionParse(key).then(results => {
      if (key === 'Apk') {
        this._apkOptions = this.getOptionsData(results, 'id');
      }
    })
      .catch(function (error) {
        this.showNotification('danger', key + ' options cannot be fetched');
      });
  }
}
