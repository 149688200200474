import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import {TokenStorage} from "./token.storage";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public router: Router, private tokenStorage: TokenStorage) {}

    canActivate() {
      const user = this.tokenStorage.getToken();
      if (user) return true;
      // not logged in so redirect to login page with the return url
      this.router.navigate(['login']);
      return false;
  }
}
