import {AfterViewInit, Component, EventEmitter, Injector, Input, OnInit, Output} from '@angular/core';
import {Pagination} from "../../common/components/pagination";
import {FormBuilder, FormGroup} from "@angular/forms";
import {ModalService} from "../_modal/modal.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-rental-dashboard',
  templateUrl: './rental-dashboard.component.html',
  styleUrls: ['./rental-dashboard.component.css']
})
export class RentalDashboardComponent extends Pagination implements OnInit {
  form: FormGroup;
  loadingBtn = false;
  phonenumber = '';
  ref_id = '';
  selection: any = {};
  filters: any = [];

  constructor(injector: Injector, protected _fb: FormBuilder, protected modalService: ModalService, protected router: Router) {
    super(injector);
    this.form = this._fb.group({});
    this.tablesData = this.cm.getRentalAttributes();
    this.pointers = [
      {
        objectName: 'chargingBox',
        relationName: 'pickedFrom'
      },
      {
        objectName: 'chargingBox',
        relationName: 'returnedTo'
      },
      {
        objectName: 'User',
        relationName: 'user'
      },
      {
        objectName: 'RentalInvoice',
        relationName: 'invoice'
      },
      {
        objectName: 'Address',
        relationName: 'pickedFrom.address'
      },
      {
        objectName: 'Address',
        relationName: 'returnedTo.address'
      },
      {
        objectName: 'Battery',
        relationName: 'battery'
      },
      {
        objectName: 'chargingBox',
        relationName: 'battery.chargingbox'
      }
    ];
    this.objectName = 'Rental';
    this.pageSize = 10;
    this.getProducts();
  }

  ngOnInit() {
    // const RentalPreAuth = this.cm.ParseJs.Object.extend('RentalPreAuth');
    // let rentalPreAuthQuery = new this.cm.ParseJs.Query(RentalPreAuth);
    // rentalPreAuthQuery.find().then(res=>{
    //   debugger
    // })
  }

  _applyFilter(event) {
    if (this.phonenumber !== '' || this.ref_id !== '') {
      this.filters = [event];
      this.getProducts();
    }
  }

  resetFilter() {
    this.phonenumber = '';
    this.filters = [];
    this.getProducts();
  }

  getProducts() {
    this.loading = true;
    this.cm.cloudFunction('fetchAllResults', {
      objectName: this.objectName, pointers: this.pointers, pageNumber: this.pageNumber,
      pageSize: this.pageSize, filters: this.filters, withCount: true
    }).then(result => {
      this.count = result.count;
      this.tablesData.data = this.cm.getParseData(result.results, this.pointers);
      this.tablesData = {...this.tablesData};
      this.loading = false;
      console.log(result);
      //For Rental
      if (this.objectName === 'Rental') {
        result.results.map((renatlObj, index) => {
          // Parse data for nested relation
          this.tablesData.data[index]['loadingBtn'] = false; // not using yet can use it in future to show loading sign in table row button
          this.tablesData.data[index]['rentalStatus'] = renatlObj.attributes['status'];
          if (renatlObj.attributes['pickedFrom'] && renatlObj.attributes['pickedFrom']['attributes']['address']) {
            const object = {};
            object['pickedFromAddress'] = this.cm.formatResult(renatlObj.attributes['pickedFrom']['attributes']['address'], 'pickedFromid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['returnedTo'] && renatlObj.attributes['returnedTo']['attributes']['address']) {
            const object = {};
            object['returnToAddress'] = this.cm.formatResult(renatlObj.attributes['returnedTo']['attributes']['address'], 'returnToid');
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['invoice']) {
            const object = {};
            object['amountCharged'] = renatlObj.attributes['invoice']['attributes']['netPayableAmount'] + (renatlObj.attributes['invoice']['attributes']['preAuthAmount'] ? renatlObj.attributes['invoice']['attributes']['preAuthAmount'] : 0);
            object['rental'] = this.cm.formatResult(renatlObj.attributes['invoice'], 'rentalid'); // 0 index because find return array
            this.tablesData.data[index] = {...this.tablesData.data[index], ...object};
          }
          if (renatlObj.attributes['battery'] && renatlObj.attributes['battery']['attributes']['chargingBox']) {
            this.tablesData.data[index]['insideStation'] = renatlObj.attributes['battery']['attributes']['chargingBox']['attributes']['deviceName'];
          } else {
            this.tablesData.data[index]['insideStation'] = 'None';
          }

        });
      }
      this.tablesData.data
      // debugger
    }, error => {
      if (error.code === 209) {
        this.cm.showNotification('danger', 'Session expired, login again');
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1500);
      } else {
        this.cm.showNotification('danger', error);
      }
    });
  }

  minutesToCharge;
  refundAmount;
  maxRefundAmount = 0;
  rentalId;

  _submit(object) {//transaction - Preauth - Balance
    const row = object.row;
    const type = object.type;
    this.rentalId = row.object.id;
    // if (type === 'partialRefund') {
    //   this.refundAmount = row.invoice.netPayableAmount + row.invoice.preAuthAmount ? row.invoice.preAuthAmount : 0;
    //   this.maxRefundAmount = row.invoice.netPayableAmount + row.invoice.preAuthAmount ? row.invoice.preAuthAmount : 0;
    // }
    this.openModal(type);
    // this.cm.cloudFunction('', {});
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  forceReturn(id: string) {
    this.loadingBtn = true;
    if (this.selection.selected && this.selection.selected.length) {
      let userObjectOperations = [];
      this.selection.selected.map(data => {
        if (data['rentalStatus'] !== 'returned') {
          userObjectOperations.push(this.cm.cloudFunction('forceCompleteRental ', {
            minutesToCharge: this.minutesToCharge
            , rentalId: data['object']['id']
          }));
        }
      });
      if (userObjectOperations.length) {
        Promise.all(userObjectOperations).then(savedAll => {
          this.loadingBtn = false;
          this.modalService.close(id);
          this.cm.showNotification('success', 'Operation successful');
        });
      } else {
        this.loadingBtn = false;
        this.modalService.close(id);
        this.cm.showNotification('danger', 'Select atleast one rental with status not returned');
      }
    } else { //single return
      this.cm.cloudFunction('forceCompleteRental', {
        rentalId: this.rentalId,
        minutesToCharge: this.minutesToCharge
      }).then(res => {
        this.loadingBtn = false;
        this.modalService.close(id);
        this.cm.showNotification('success', 'Operation successful');
      });
    }
  }

  _selectionModelChange(event) {
    this.selection = event;
  }

  partialRefund(id: string) {
    this.loadingBtn = true;
    // this.cm.cloudFunction('partialRefund', {
    //       rentalId: this.rentalId,
    //       runPaymentLogic: true,
    //       rentedDurationMinutes: parseInt(this.refundAmount, 10)
    //     }).then(response => {
    //       this.loadingBtn = false;
    //       this.cm.showNotification('success', response);
    //       this.modalService.close(id);
    //
    //     }, err => {
    //       this.loadingBtn = false;
    //       this.modalService.close(id);
    //       this.cm.showNotification('danger', err);
    //     });
    this.cm.cloudFunction('partialRefund', {
      rentalId: this.rentalId,
      doCalculationsOnly: true,
      rentedDurationMinutes: parseInt(this.refundAmount, 10)
    }).then(res => {
      if (confirm(res)) {
        this.cm.cloudFunction('partialRefund', {
          rentalId: this.rentalId,
          doCalculationsOnly: false,
          rentedDurationMinutes: parseInt(this.refundAmount, 10)
        }).then(response => {
          this.loadingBtn = false;
          this.cm.showNotification('success', response);
          this.modalService.close(id);

        }, err => {
          this.loadingBtn = false;
          this.modalService.close(id);
          this.cm.showNotification('danger', err);
        });
      } else {
        this.loadingBtn = false;
      }
    }, error => {
      this.loadingBtn = false;
      this.modalService.close(id);
      this.cm.showNotification('danger', error);
    });

  }

  calc() {
    // const dateEnd = new Date();
    // const dateStart = new Date();
    // dateEnd.setHours(0, 0, 0);
    // dateStart.setHours(0, 0, 0);
    // const first = dateStart.getDate() - 14; // First day is the day of the month - the day of the week
    // dateStart.setDate(first);
    // dateEnd.setDate(dateEnd.getDate() + 1);
    // const Product = this.cm.ParseJs.Object.extend('Rental');
    // const query = new this.cm.ParseJs.Query(Product);
    // query.greaterThanOrEqualTo('rentedAt', dateStart);
    // query.lessThan('rentedAt', dateEnd);
    // query.limit(100000);
    // query.descending('rentedAt');
    // query.find().then(results => {
    //   debugger
    //
    // });


    //45 5 25 type stattions
    // const Product = this.cm.ParseJs.Object.extend('ChargingBox');
    // const query = new this.cm.ParseJs.Query(Product);
    // query.include('address');
    // query.exists('address');
    // query.notEqualTo('address', '');
    //
    // query.limit(100000);
    // query.include('type');
    // const child = this.cm.ParseJs.Object.extend('ChargingBoxType');
    // let innerQuery = new this.cm.ParseJs.Query(child);
    // innerQuery.equalTo('totalSlots', 5);
    // query.matchesQuery('type', innerQuery);


    const ChargingBoxStatus = this.cm.ParseJs.Object.extend('ChargingBoxStatus');
    const parentquery = new this.cm.ParseJs.Query(ChargingBoxStatus);
    parentquery.equalTo('status', 'online');
    parentquery.limit(100000);
    parentquery.include('chargingBox');
    parentquery.include('chargingBox.address');
    parentquery.include('chargingBox.type');

    const Product = this.cm.ParseJs.Object.extend('ChargingBox');
    const query = new this.cm.ParseJs.Query(Product);
    query.include('address');
    query.exists('address');
    query.notEqualTo('address', '');
    parentquery.matchesQuery('chargingBox', query);
    query.include('type');
    // const child = this.cm.ParseJs.Object.extend('ChargingBoxType');
    // const innerQuery = new this.cm.ParseJs.Query(child);
    // innerQuery.equalTo('totalSlots', 5);
    // query.matchesQuery('type', innerQuery);
    let data = [];
    parentquery.find().then(results => {
      debugger
      results.map(obj => {
        data.push(obj.toJSON());
      });

      debugger
      let five = 0;
      let twentyfive = 0;
      let fourtyfive = 0;
      data.map(obj => {
        if (obj.chargingBox.type && obj.chargingBox.type.totalSlots) {
          if (obj.chargingBox.type.totalSlots === 5)
            five++;
          if (obj.chargingBox.type.totalSlots === 25)
            twentyfive++;
          if (obj.chargingBox.type.totalSlots === 45)
            fourtyfive++;
        }
      });
      debugger
    });
  }
}
