import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs/Observable';
import {Subject} from 'rxjs/Subject';

import {TokenStorage} from './token.storage';
import {Router} from "@angular/router";
import {CommonService} from "../../common/services/common-service";

@Injectable()
export class AuthService {

  constructor(private token: TokenStorage, private router: Router, private cm: CommonService) {
  }

  parseLogin(username, password) {
    this.cm.ParseJs.User.logIn(username, password).then(user => {
      this.token.saveToken(user['attributes']['username']);
      this.router.navigate(['']);
    }, error => {
      this.cm.showNotification('danger', error);
    });
  }

  parseSignup() {
    let user = new this.cm.ParseJs.User();
    user.set("username", "danishumer");
    user.set("password", "Danishumer123456");
    user.set("email", "email@example.com");
    user.signUp().then(success => {
      alert(success);
    }, error=>{
      alert(error);
    });
  }
  signOut(): void {
    this.token.signOut();
    delete (<any>window).user;
  }
}
