import {NgModule} from '@angular/core';
import {
  MatCommonModule, MatFormFieldModule, MatInputModule, MatNativeDateModule, MatOptionModule, MatPaginatorModule,
  MatSortModule
} from '@angular/material';
import {LayoutModule} from '@angular/cdk/layout';
import {ScrollDispatchModule} from '@angular/cdk/scrolling';
import {OverlayModule} from '@angular/cdk/overlay';
import {CommonModule} from '@angular/common';
import {PortalModule} from '@angular/cdk/portal';
import {MatRadioModule} from '@angular/material/radio';
import {MatIconModule} from '@angular/material';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {TextMaskModule} from 'angular2-text-mask';

import {MatSelectModule} from '@angular/material/select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatTableModule} from '@angular/material/table';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatStepperModule} from '@angular/material/stepper';
import {MatCardModule} from '@angular/material/card';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {PlatformModule} from "@angular/cdk/platform";
import {MatGridListModule} from '@angular/material/grid-list';
import {ModalModule} from "../app/_modal/modal.module";
const MODULES = [
  MatFormFieldModule,
  ModalModule,
  MatProgressSpinnerModule,
  MatInputModule,
  PortalModule,
  CommonModule,
  OverlayModule,
  ScrollDispatchModule,
  MatOptionModule,
  MatCommonModule,
  LayoutModule,
  MatIconModule,
  MatRadioModule,
  TextMaskModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatSlideToggleModule,
  MatProgressBarModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatStepperModule,
  MatTabsModule,
  PlatformModule,
  MatButtonModule,
  MatCardModule,
  MatGridListModule
];

@NgModule({
  imports: [
    ...MODULES
  ],
  exports: [
    ...MODULES
  ]
})
export class AppMaterial {
}
