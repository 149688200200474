import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";


import {DataTableComponent} from "./data-table.component";
import {AppMaterial} from "../../app-material";
import {InputControlModule} from "../input-control/input-control-module";
import {DataTableDropdownComponent} from "./data-table-dropdown/data-table-dropdown.component";
import {DataTableActionsComponent} from "./data-table-actions/data-table-actions.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    AppMaterial,
    InputControlModule
  ],
  declarations: [
    DataTableComponent,
    DataTableDropdownComponent,
    DataTableActionsComponent
  ],
  providers: [
  ],
  exports: [
    DataTableComponent,
    DataTableDropdownComponent,
    DataTableActionsComponent
  ]
})
export class DataTableModule {
}
