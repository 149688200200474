import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from "@angular/forms";
import {AuthService} from "./auth.service";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  data = [
    {
      key: 'email',
      placeholder: 'email',
      value: '',
      label: 'email:',
      type: 'email',
    },
    {
      key: 'password',
      placeholder: 'password',
      value: '',
      label: 'password:',
      type: 'password',
    }
  ]
  form: FormGroup;

  get email() {
    return this.form.value['email'];
  }

  get password() {
    return this.form.value['password'];
  }

  constructor(private _fb: FormBuilder, private authService: AuthService) {
    this.form = this._fb.group({});
  }

  ngOnInit() {
    // this.authService.signOut();
  }

  login(): void {
    this.authService.parseLogin(this.email, this.password);
    // this.authService.parseSignup();
  }
  sihnup(): void {
    debugger
    this.authService.parseSignup();
    // this.authService.parseSignup();
  }

}
