import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CommonService} from "../../../services/common-service";

@Component({
  selector: 'cm-data-table-actions',
  templateUrl: './data-table-actions.component.html',
  styleUrls: ['./data-table-actions.component.css']
})
export class DataTableActionsComponent implements OnInit {
  @Input() cols = 4;
  @Input() form;
  @Input() config = [
    {
      title: 'Apk Version',
      prop: {
        key: 'apkVersion',
        type: 'select',
        label: 'APK',
        prop: {
          key: 'apk',
          placeholder: 'Apk',
          value: '',
          label: '',
          type: 'select',
          isOptionsPromise: true,
          options: this.cm.getOptionParse('Apk'),
          viewValue: 'apkVersion',
          viewKey: 'id'
        }
      }
    },
    {
      title: 'Status',
      prop: {
        key: 'status',
        placeholder: 'Online Offline Status',
        value: '',
        label: '',
        type: 'select',
        valueType: 'simple',
        options: [
          {
            id: 'online',
            value: 'online'
          },
          {
            id: 'offline',
            value: 'offline'
          }
        ],
        viewValue: 'value',
        viewKey: 'id'
      }
    }
  ];
  @Output() submit = new EventEmitter();

  constructor(private cm: CommonService) {
  }

  ngOnInit() {
  }

  _submit() {
    this.submit.emit(this.form.value);
  }
}
