import {AfterViewInit, Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FieldConfig} from "../field-config";
import {CommonService} from "../../../services/common-service";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";

@Component({
  selector: 'ft-dropdown-input',
  templateUrl: './dropdown-input.component.html',
  styleUrls: ['./dropdown-input.component.scss']
})
export class DropdownInputComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  optionsShow = false;
  options: any = [];
  @Input() form;
  _prop: FieldConfig;
  @Input()
  set prop(prop) {
    this._prop = prop;
    if (prop) {
      this.addControl(prop);
      this.addValidators(prop);
      this.addOptions(prop);
    }
  }

  get prop() {
    return this._prop;
  }

  ismobile = false;

  constructor(private cm: CommonService, breakpointObserver: BreakpointObserver) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
      Breakpoints.HandsetPortrait
    ]).subscribe(result => {
      if (result.matches) {
        this.ismobile = true;
      } else {
        this.ismobile = false;
      }
    });
  }

  ngAfterViewInit() { //get(this.prop['key']).
    this.form.valueChanges.subscribe(data => {
      let multiselectArray = [];
      if (this.prop['type'] === 'select') {
        if (this.prop.change && data[this.prop.key] && this.prop['valueType'] !== 'simple') { // && this.prop['valueType'] !== 'simple'
          this.prop.change(this.prop['options'].find(x => x['id'] === data[this.prop.key]['id']));
          console.log('afterviewinit');
        }
      } else if (this.prop['type'] === 'multiselect') {
        // if (data[this.prop.key])
        //   data[this.prop.key].map(value => {
        //     const Object = this.prop['options'].find(x => x['id'] === value);
        //     multiselectArray.push(Object['object']);
        //   });
        if (this.prop.change)
          this.prop.change(data, this.prop['options']);
      }
    });
  }

  ngOnInit() {
  }

  ngOnChanges() {
  }

  get typeofOptions() {
    if (Promise && Promise.resolve) {
      return Promise.resolve(this.prop['options']) === this.prop['options'];
    }
  }

  addOptions(prop) {
    if (prop['isOptionsPromise']) {
      prop['options'].then(results => {
        prop['options'] = this.cm.getOptionsData(results, 'id');
        // if (prop['options'].length && this.prop['type'] === 'select') {// default select first value
        //   this.form.get(prop.key).setValue(prop['options'][0][prop.viewKey]);
        //   if(this.prop.change)
        //     this.prop.change(prop['options'][0]);
        // }
        // else if (prop['options'].length && this.prop['type'] === 'multiselect') {
        //   this.prop.change(prop['options'][0], prop['options']);
        // }
        this.optionsShow = true;
        console.log(prop['options']);
        debugger
        prop['isOptionsPromise'] = false;
      })
        .catch(function (error) {
          console.log(error);
          this.cm.showNotification('danger', error);
          // There was an error.
        });
    } else this.optionsShow = true;
  }

  addControl(prop) {
    this.form.addControl(prop.key, new FormControl(prop.value));
  }

  addValidators(prop) {
    if (prop.validation) {
      const keys = Object.keys(prop.validation);
      const validators = [];
      for (const key of keys) {
        if (key === 'required') {
          validators.push(Validators.required);
        } else if (key === '') {

        }
      }
      this.form.controls[prop.key].setValidators(validators);
    }
  }

  _selectionChange(event) {
    // let multiselectArray = [];
    if (this.prop['type'] === 'select') {
      if (this.prop.key === 'type') this.form.patchValue({apk: null});
      if (this.prop.change) {
        this.prop.change(event['value']);
      }
    } else {
      // event['value'].map(value => {
      //   const Object = this.prop['options'].find(x => x['id'] === value);
      //   multiselectArray.push(Object['object']);
      // });
      if (this.prop.change) {
        this.prop.change(event, this.prop['options']);
      }
    }
  }

  compareFn(a, b) {
    console.log(a['id'], b['id']);
    return a && b && a['id'] === b['id'];
  }

  getErrorMessage() {
    if (this.form.controls[this.prop.key].touched) {
      if (this.form.controls[this.prop.key].hasError('required')) {
        return this.prop.errorMessage['required'];
      }
      else return '';
    }

  }

  progressBarClass(value) {
    if (value >= 80) return 'pb-success';
    if (value < 80 && value >= 50) return 'pb-warning';
    if (value < 50) return 'pb-danger';
    return '';
  }

  progressBarPercentageClass(value) {
    if (value >= 80) return 'text-success';
    if (value < 80 && value >= 50) return 'text-warning';
    if (value < 50) return 'text-danger';
    return '';
  }

  ngOnDestroy() {
  }
}
