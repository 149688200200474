import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {AuthGuard} from './login/auth-guard.service';
import {UserListComponent} from './user-list/user-list.component';
import {LoginComponent} from './login/login.component';
import {RentalDashboardComponent} from './rental-dashboard/rental-dashboard.component';
import {RentalRefundComponent} from './rental-dashboard/rental-refund/rental-refund.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: '',
    component: RentalDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-list',
    component: UserListComponent,
    canActivate: [AuthGuard]
  },
   {
    path: 'rentals',
    component: RentalDashboardComponent, canActivate: [AuthGuard]

  },
  {
    path: 'refund',
    component: RentalRefundComponent, canActivate: [AuthGuard]

  }
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule {
}

